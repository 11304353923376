import Crypto from "crypto";
export default {
    makeSign(bodyString, appId, appKey) {
        let sha1 = this.myCrypto(appKey + bodyString + appKey, 'sha1')
        let md5 = this.myCrypto(sha1 + appId, 'md5')
        return md5;
    },
    myCrypto(str, type) {
        let md5 = Crypto.createHash(type);
        md5.update(str);
        return md5.digest('hex');
    },
    objectKeySort(arys) {
        let newKey = Object.keys(arys).sort()
        let newObj = ''
        for (let i = 0; i < newKey.length; i++) {
            let value = arys[newKey[i]]
            let key = [newKey[i]]
            let type = typeof value
            if (type == 'number' && value != null) {
                newObj += key + '=' + value + '&'
            } else if (type == 'string' && this.isNotEmpty(value)) {
                newObj += key + '=' + value + '&'
            } else {
                newObj += key + '=' + value + '&'
            }
        }
        return newObj.substring(0, newObj.length - 1)
    },
    getUrlQuery: function(name, Url) {
        var reg = new RegExp('(^|\\?|&)' + name + '=([^&]*)(\\s|&|$|#)', 'i'), url = Url || location.search
        if (reg.test(url)) {
            return decodeURI(RegExp.$2.replace(/\+/g, ' '))
        }
        return ''
    },
    isNotEmpty : function(string) {
        if (string == null) {
            return false
        }
        string = string + ''
        return string != undefined && string != null && string.length > 0 ? true : false;
    },
    isEmpty: function(string) {
        return this.isNotEmpty(string) ? false : true;
    },
    findDictionary: function(array, key){
        if (array == null) {
            return null;
        }
        return array.find(f => f['diValue'] == key)
    },
    findDictionaryName: function(dictionary = [], key) {
        const dictionaryItem = this.findDictionary(dictionary, key)
        if (dictionaryItem == null) {
            return ''
        }
        return dictionaryItem['diName'] || ''
    },
    isIOS: function() {
        var u = navigator.userAgent;
        return u.indexOf('Mac OS') > -1
    },
    isWx: function() {
        var ua = window.navigator.userAgent.toLowerCase();
        return ua.match(/MicroMessenger/i) == 'micromessenger' ? true : false
    }
}

