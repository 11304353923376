<template>
  <van-overlay :show="isShow" style="display: flex; justify-content: center; align-items: center; align-content: center; z-index: 500" >
    <van-loading size="48" />
  </van-overlay>
</template>

<script>
import { Loading, Overlay } from 'vant';
import 'vant/es/dialog/style';
export default {
  name: "MobileLoadingView",
  components: { VanLoading: Loading, VanOverlay: Overlay },
  props: {
    isShow: {
      default: false
    }
  }
}
</script>

<style scoped>

</style>