import request from './request'

export default {
    queryCompany(domain) {
        return request()({
            url: '/OfficialWebsiteService/QueryCompany',
            method: 'get',
            data: {
                appSignType: 'DIGEST',
                domain: domain
            }
        })
    },
    queryProductList(domain) {
        return request()({
            url: '/OfficialWebsiteService/QueryProductList',
            method: 'get',
            data: {
                appSignType: 'DIGEST',
                domain: domain
            }
        })
    },
    queryProduct(dpId) {
        return request()({
            url: '/OfficialWebsiteService/QueryProduct',
            method: 'get',
            data: {
                appSignType: 'DIGEST',
                dpId: dpId
            }
        })
    },
    queryBannerList(domain) {
        return request()({
            url: '/OfficialWebsiteService/QueryBannerList',
            method: 'get',
            data: {
                appSignType: 'DIGEST',
                domain: domain
            }
        })
    },
    getApplicationShareConfig(applicationId) {
        return request()({
            url: '/ApplicationService/GetApplicationShareConfig',
            method: 'get',
            data: {
                appSignType: 'DIGEST',
                applicationId: applicationId
            }
        })
    },
    invoiceQuery(data) {
        return request()({
            url: '/InvoiceQuery/InvoiceQuery',
            method: 'post',
            data: {
                appSignType: 'DIGEST',
                ...data
            }
        })
    },
    getPurchaseConfig(data) {
        return request()({
            url: '/PurchaseMemberConfigService/GetPurchaseConfig',
            method: 'post',
            data: {
                appSignType: 'DIGEST',
                ...data
            }
        })
    },
    queryFixedPrice(data) {
        return request()({
            url: '/PayService/QueryFixedPrice',
            method: 'post',
            data: {
                appSignType: 'DIGEST',
                ...data
            }
        })
    },
    getCommunityCacheData() {
        return request()({
            url: '/CommunityService/GetCommunityCacheData',
            method: 'post',
            data: {
                appSignType: 'DIGEST'
            }
        })
    },
    allDictionaryItems(keys) {
        return request()({
            url: '/DictionaryService/allDictionaryItems',
            method: 'post',
            data: {
                appSignType: 'DIGEST',
                keys: keys
            }
        })
    },

}
