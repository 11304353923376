import axios from 'axios'
import qs from 'qs'
import Common from '../utils/common'
import RewardPointsBridge from '@/utils/rewardPointsBridge'

function getAppToken() {
    try {
        let appToken = RewardPointsBridge.getUserToken(false)
        if (Common.isNotEmpty(appToken)) {
            return appToken;
        }
    } catch (e) {
        console.warn(e)
    }
    return Common.getUrlQuery("appToken")
}
function getApplicationId() {
    try {
        let applicationId = RewardPointsBridge.getAppId(false)
        if (Common.isNotEmpty(applicationId)) {
            return applicationId;
        }
    } catch (e) {
        console.warn(e)
    }
    return Common.getUrlQuery("applicationId")
}
function getPlatformName() {
  try {
      let platformName = RewardPointsBridge.getPlatformName(false)
      if (Common.isNotEmpty(platformName)) {
          return platformName;
      }
  } catch (e) {
      console.warn(e)
  }
  return Common.getUrlQuery("applicationId")
}
function initAxios(url = process.env.VUE_APP_BASE_API, accessKey = process.env.VUE_APP_ACCESS_KEY, accessSecret = process.env.VUE_APP_ACCESS_SECRET) {
  const service = axios.create({
    baseURL: url, // api的base_url
    timeout: 1000 * 30 // request timeout
  })

  // request interceptor
  service.interceptors.request.use(config => {
    const fromData = config.data || {}
    let appToken = getAppToken()
    if (appToken != null && appToken != '') {
        fromData['appToken'] = appToken
    }
    let applicationId = getApplicationId()
    if (applicationId != null && applicationId != '') {
      fromData['applicationId'] = applicationId
    }
    let platformName = getPlatformName()
    if (platformName != null && platformName != '') {
      fromData['platformName'] = platformName
    }
    fromData['appId'] = accessKey
    const bodyData = Common.objectKeySort(fromData)
    const sign = Common.makeSign(bodyData, accessKey, accessSecret)
    fromData['appSign'] = sign
    if(config.method=='post') {
        config.data = qs.stringify(fromData, { allowDots: true })
    }else if(config.method=='get') {
        config.params = fromData
    }
    return config
  }, error => {
    Promise.reject(error)
  })
  
  // respone interceptor
  service.interceptors.response.use(
    response => {
      // Toast.clear();
      const res = response.data
      if (res.statusCode === 403) {
         console.log("403")
      } else if (res.statusCode !== 200) {
        return Promise.reject(res)
      } else {
        return Promise.resolve(res)
      }
    }, error => {
      return Promise.reject(error)
    }
  )
  return service
}

export default initAxios
