export default {
    debug: true,
    state: {
        invoiceHeaderData: {},
        mail: ''
    },
    setInvoiceHeaderData (newValue) {
        this.state.invoiceHeaderData = newValue
    },
    clearInvoiceHeaderData () {
        this.state.invoiceHeaderData = {}
    }
}